import React, { useEffect, useContext } from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { StaticImage } from "gatsby-plugin-image"

import { GlobalStateContext } from "../context/GlobalContextProvider"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Share from "../components/share"
import Img from "gatsby-image"

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const state = useContext(GlobalStateContext)
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  const {
    frontmatter: {
      title,
      description,
      englishBody,
      linkProject,
      englishTitle,
      englishDescription,
      bodyImages,
    },
    fields: { slug },
  } = post

  const {
    site: {
      siteMetadata: {
        siteUrl,
        social: { twitter },
        image,
      },
    },
  } = data

  useEffect(() => {
    let htmlContent = document.getElementById("body-post")
    const allLinks = htmlContent.querySelectorAll("a[href]")
    allLinks && allLinks.forEach(link => link.setAttribute("target", "_blank"))

    bodyImages && bodyImages.map((img, index) => console.log("img: ", img))
  }, [bodyImages])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={title}
        description={description || post.excerpt}
        url={`${siteUrl}/portfolio/${slug}`}
        image={image}
        // meta={[description, title]}
      />
      <section className="section-light blogPost">
        <div className="wrapper medium">
          {state.language.current === "english" ? (
            <>
              <h1>{englishTitle}</h1>
              <h4>{englishDescription}</h4>
            </>
          ) : (
            <>
              <h1>{title}</h1>
              <h4>{description}</h4>
            </>
          )}
          <div data-grid="center spacing" className="page-content">
            <small data-cell="shrink">
              {/* {post.frontmatter.date} */}
              Developed in{" "}
              <a href={post.frontmatter.linkCompany} target="_blank" rel="noopener noreferrer">
                {post.frontmatter.company}
              </a>
            </small>
            <div data-cell="shrink" data-grid="spacing center row around">
              <Share
                socialConfig={{
                  twitter,
                  config: {
                    url: `${siteUrl}/portfolio/${slug}`,
                    title,
                  },
                }}
              />
            </div>
            <small data-cell="shrink">
              <Bio />
            </small>
          </div>
          {post.frontmatter.featuredImage && (
            <div className="coverContainer">
              <Img
                sizes={post.frontmatter.featuredImage.childImageSharp.sizes}
              />
            </div>
          )}
          <section id="body-post">
            {state.language.current === "english" &&
            post.frontmatter.englishBody ? (
              englishBody || post.frontmatter.englishBody
            ) : (
              <MDXRenderer>{post.body}</MDXRenderer>
            )}
            {state.language.current === "english" && linkProject ? (
              <p>
                <a href={linkProject}>Access the website.</a>
              </p>
            ) : (
              <p>
                <a href={linkProject}>Acesse o site.</a>
              </p>
            )}
            {/* {post.frontmatter.bodyImages ||
              (bodyImages &&
                bodyImages.map((img, index) => (
                  <StaticImage
                    style={{ border: "1px solid red" }}
                    src={img}
                    key={index}
                    alt="Images from project"
                  />
                )))}

            <img src="https://wallpaperaccess.com/full/3456129.jpg" alt="" /> */}
          </section>
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />

          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={`blog${previous.fields.slug}`} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`blog${next.fields.slug}`} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        author
        social {
          twitter
        }
        image
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        englishTitle
        date(formatString: "MMMM DD, YYYY")
        description
        englishDescription
        linkCompany
        linkProject
        company
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        englishBody
        bodyImages
      }
      fields {
        slug
      }
    }
  }
`
